<template>
  <div>
    <h1>Update account</h1>

    <div v-if="error_text" class="w-full lg:w-1/2 my-4 text-red-600 bg-red-200 p-6 pt-4 pb-4 bg-white rounded shadow-md leading-loose">
        <strong>Something went wrong:</strong> <span v-html="error_text"></span>
    </div>

    <div class="w-full lg:w-1/2 my-4">
        <form class="pt-2 p-6 bg-white rounded shadow-lg leading-loose">
            <div>
                <label class="block text-gray-600 py-3">
                    Account Name:
                </label>
                <input class="w-full px-3 py-1 text-gray-700 bg-gray-200 rounded" type="text" v-model="name" placeholder="My Company">
            </div>
            <div>
                <label class="block text-gray-600 py-3">
                    Account Purpose:
                </label>
                <select v-model="purpose" class="w-full h-9 px-3 py-1 text-gray-700 bg-gray-200 rounded">
                <option value="MESSAGES">Messages</option>
                <option value="SCRAPING">Scraping</option>
                </select>
            </div>
            <div>
                <label class="block text-gray-600 py-3">
                    <strong>IG - </strong> Session ID:
                </label>
                <span class="block w-full px-3 py-1 text-gray-700 bg-gray-200 rounded">
                    {{session_id}}
                </span>
            </div>
            <div>
                <label class="block text-gray-600 py-3">
                    <strong>IG - </strong> Username:
                </label>
                <input class="w-full px-3 py-1 text-gray-700 bg-gray-200 rounded" type="text" v-model="username" placeholder="my_cool_company">
            </div>
            <div>
                <label class="block text-gray-600 py-3">
                    <strong>IG - </strong> Password:
                </label>
                <input class="w-full px-3 py-1 text-gray-700 bg-gray-200 rounded" type="password" v-model="password">
            </div>
            <div class="w-full p-2 mt-4 text-sm text-yellow-600 bg-yellow-100 text-center rounded">
                ⚠️ The password is only used to get a new session ID - we don't save it at any time.
            </div>
            <div class="grid grid-cols-2 mt-6">
                <div class="justify-self-start">
                    <button class="px-4 py-1 text-white font-light tracking-wider bg-gray-500 rounded" @click="updateAccount">
                        Update account
                    </button>
                </div>
                <div class="justify-self-end">
                    <button class="px-4 py-1 text-white font-light tracking-wider bg-red-500 rounded" @click="deleteAccount">
                        Delete account
                    </button>
                </div>
            </div>
        </form>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['accountId'],

  data() {
    return {
        name: null,
        purpose: null,
        username: null,
        password: null,
        session_id: null,
        error_text: null,
    }
  },
  created () {
    axios.get(`${process.env.VUE_APP_API_URL}/accounts/${this.accountId}`)
      .then(response => {
        this.name = response.data.name;
        this.purpose = response.data.purpose;
        this.username = response.data.username;
        this.session_id = response.data.session_id;
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    updateAccount(evt) {
      evt.preventDefault();
      evt.srcElement.disabled = true;
      evt.srcElement.innerText = 'Please be patient...';
      
      axios.put(`${process.env.VUE_APP_API_URL}/accounts/${this.accountId}`, {
            name: this.name,
            purpose: this.purpose,
            username: this.username,
            password: this.password,
        })
        .then(() => {
            this.$router.push(`/accounts`)
        })
        .catch(error => {
            evt.srcElement.disabled = false;
            evt.srcElement.innerText = 'Update account';

            switch (error.response.data.status)
            {
                case 'wrong-credentials':
                    this.error_text = 'Invalid username or password.';
                    break;
                case 'suspisious-login':
                    this.error_text = 'Instagram has detected a suspisious login on your account. Please <a href="https://www.instagram.com/session/login_activity/" target="_blank" class="underline">click here</a> and verify it was you there tried from "Mountain View, California" - after your confirmation, please try to create the account again.';
                    break;
                default:
                    this.error_text = 'An unknown error. Please try again.'
                    break;
            }
        });
    },
    deleteAccount(evt) {
        if (confirm('Are you sure you want to delete this account?'))
        {
            evt.preventDefault();

            axios
                .delete(`${process.env.VUE_APP_API_URL}/accounts/${this.accountId}`)
                .then(() => {
                    this.$router.push(`/accounts`)
                })
                .catch(() => {
                    this.error_text = 'The account is used in one or more projects.';
                });
        }
    }
  }
}
</script>